import React from 'react';
import { Link } from 'gatsby';
import { PortableText } from '@portabletext/react';

import Section from '../common/section';
import HeroCarousel from './hero-carousel';
// import Fade from '@/components/common/fade';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';
import { trackEvent } from '@/utils/amplitude-tracking';
import { HiArrowRight } from 'react-icons/hi';

export const portableText = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <span className="title-hl">{children}</span>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const CTALink = ({ link, className }) => {
  if (link._type === 'internalLink') {
    return (
      <Link
        onClick={() => trackEvent(link)}
        to={`/${link.slug.current}`}
        className={className}
      >
        {link.label}
        <HiArrowRight />
      </Link>
    );
  }
  if (link._type === 'externalLink') {
    return (
      <OutboundLink
        href={link.url}
        onClick={() => trackEvent(link)}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {link.label}
        <HiArrowRight />
      </OutboundLink>
    );
  }
};

const ResearchHero = ({ researchHeroSectionData }) => {
  const { _rawTitle, _rawSubtitle, _rawText, ctaLinksV2, carouselImages } =
    researchHeroSectionData;
  return (
    <Section className="relative z-10">
      {/* background */}
      <div className="absolute left-hero-gradient-spacing top-[30%] -z-10 h-[15.625rem] w-[37.5rem] bg-gradient-hero blur-[9.1rem] md:top-[50%]" />

      <div className="flex h-full flex-col-reverse justify-between py-6 md:flex-row md:gap-8 lg:pb-8 lg:pt-4">
        {/* left */}
        <div className="relative z-20 flex w-full max-w-xl flex-grow flex-col justify-between text-left md:w-[50%]">
          {/* Title */}
          <h1 className="hidden text-4xl leading-tight text-white md:block md:text-5xl lg:text-[3.5rem]">
            <PortableText value={_rawTitle} components={portableText as any} />
          </h1>
          <div className="my-8 w-full md:max-w-[80%] lg:max-w-[70%]">
            {/* Subtitle */}
            <h3 className="text-2xl font-normal text-white md:text-4xl">
              <PortableText value={_rawSubtitle} />
            </h3>
            {/* Text */}
            <div className="my-4 text-lg font-light text-white/80">
              <PortableText value={_rawText} />
            </div>
            {/* CTA */}
            {ctaLinksV2.map((link, index) => (
              <CTALink
                key={`cta-link-${index}`}
                link={link}
                className="mt-10 flex items-center gap-3 text-orangey-yellow hover:opacity-80"
              />
            ))}
          </div>
        </div>
        {/* right - carousel */}
        <div className="min-h-80 w-full md:w-[50%]">
          {/* Title on mobile */}
          <h1 className="mb-8 mt-4 text-4xl leading-tight text-white md:hidden">
            <PortableText value={_rawTitle} components={portableText as any} />
          </h1>
          <HeroCarousel carouselImages={carouselImages} />
        </div>
      </div>
    </Section>
  );
};

export default ResearchHero;
